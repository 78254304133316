import { FC, useReducer } from 'react';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import type { LocationNavItem } from '../../data';
import LocationNavButton from '../LocationNavButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { CSS_VARS } from '@/constants/css-vars';

interface IProps {
  navItem: LocationNavItem;
  toggleMobileSidebarOpen?: () => void;
}

const NavCollapse: FC<IProps> = ({
  navItem,
  toggleMobileSidebarOpen,
}): JSX.Element => {
  const [isSelected, toggle] = useReducer((s) => !s, true);
  const location = useLocation();
  const pathname = location.pathname;

  const listPaths = navItem.children?.map((child) => child.path);
  const isTabActive = listPaths?.includes(pathname);

  const textColor = isTabActive
    ? CSS_VARS.Colors.BrandBlue[400]
    : CSS_VARS.Colors.Gray[400];

  return (
    <>
      <Button
        sx={{
          height: '55px',
          width: '100%',
          textAlign: 'left',
          borderRadius: '0 5rem 5rem 0',
          px: {
            xs: '2rem',
            md: '3.5rem',
          },
          py: '1rem',
        }}
        onClick={() => {
          toggle();
        }}
      >
        <Grid
          justifyContent={'space-between'}
          alignItems="center"
          container
          gap={2}
        >
          <Typography
            color={`var(${textColor})`}
            fontWeight={isTabActive != null ? 700 : 500}
            noWrap
            variant="h5"
          >
            {navItem.text}
          </Typography>
          {isSelected ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </Grid>
      </Button>
      <Collapse in={isSelected} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {navItem?.children?.map((childItem, childIndex) => (
            <LocationNavButton
              {...childItem}
              key={childIndex}
              isSelected={childItem.path === pathname}
              isChildren={true}
              onClick={toggleMobileSidebarOpen}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default NavCollapse;
