import Box from '@mui/material/Box';
import { memo } from 'react';

import { Metadata, NavIterator } from './components';
import type { NavigationItem } from '@/features/ui/navigation';

interface BankAccountNavigationProps {
  navItemList: NavigationItem[];
  toggleMobileSidebarOpen?: () => void;
}

const BankAccountNavigation = memo((props: BankAccountNavigationProps) => {
  const { navItemList, toggleMobileSidebarOpen } = props;

  return (
    <Box
      component="aside"
      sx={{
        display: {
          xs: 'none',
          md: 'flex',
        },
        borderRight: '1px solid',
        borderColor: 'grey.100',
        gridArea: 'sidebar',
        minHeight: '100vh',
        padding: '2rem 2rem 2rem 0',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
      }}
    >
      <NavIterator
        list={navItemList}
        toggleMobileSidebarOpen={toggleMobileSidebarOpen}
      />

      <Metadata />
    </Box>
  );
});

if (import.meta.env.DEV) {
  BankAccountNavigation.displayName = 'BankAccountNavigation;';
}

export default BankAccountNavigation;
