import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { VERSION } from '@/constants/env';
import { CSS_VARS } from '@/constants/css-vars';
const legalLanguage = 'Banking services provided by Regent Bank, Member FDIC.';
const VersionNumber = VERSION;
const Footer = (): JSX.Element => {
  return (
    <Box
      component="footer"
      sx={{
        gridArea: 'footer',
        width: '100%',
        position: 'relative',
        py: '2rem',
        px: 2,
      }}
    >
      <Typography
        sx={{
          fontWeight: '500',
          fontSize: {
            xs: '1.2rem',
            md: '1.3rem',
          },
          textAlign: 'center',
        }}
      >
        {legalLanguage}
      </Typography>
      <Typography
        sx={{
          color: `var(${CSS_VARS.Colors.Gray[400]})`,
          position: 'absolute',
          right: '1.6rem',
          bottom: {
            xs: '0.4rem',
            md: '2rem',
          },
          fontWeight: '800',
          fontSize: '1rem',
        }}
      >
        {VersionNumber}
      </Typography>
    </Box>
  );
};

export default Footer;
