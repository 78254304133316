import * as z from 'zod';

const envSchema = z.object({
  DEV: z.boolean(),
  VITE_API_URL: z.string().min(1),
  VITE_AUTH0_AUDIENCE: z.string().min(1),
  VITE_AUTH0_CLIENT_ID: z.string().min(1),
  VITE_AUTH0_DOMAIN: z.string().min(1),
  VITE_SEGMENT_WRITE_KEY: z.string().min(1),
  VITE_VERSION: z.string().min(1),
  VITE_SENTRY_DSN: z.string().optional(),
  VITE_SENTRY_AUTH_TOKEN: z.string().optional(),
  VITE_DEBUG: z.string().optional(),
  VITE_ENVIRONMENT: z.string().optional(),
  VITE_PAYMENT_URL: z.string().optional(),
  VITE_MEASUREMENT_ID: z.string(),
  VITE_PAYMENT_CENTER_URL: z.string(),
  VITE_PAYMENT_ENROLLMENT_ENABLED: z
    .string()
    .optional()
    .transform((s) => {
      return s?.toLowerCase() === 'true';
    }),
});

const parsedEnv = envSchema.parse(import.meta.env);

export const {
  DEV: IS_DEV,
  VITE_API_URL: API_URL,
  VITE_AUTH0_AUDIENCE: AUTH0_AUDIENCE,
  VITE_AUTH0_CLIENT_ID: AUTH0_CLIENT_ID,
  VITE_AUTH0_DOMAIN: AUTH0_DOMAIN,
  VITE_SEGMENT_WRITE_KEY: SEGMENT_WRITE_KEY,
  VITE_VERSION: VERSION,
  VITE_SENTRY_DSN: SENTRY,
  VITE_SENTRY_AUTH_TOKEN: SENTRY_TOKEN,
  VITE_DEBUG: DEBUG,
  VITE_ENVIRONMENT: ENVIRONMENT,
  VITE_PAYMENT_CENTER_URL: PAYMENT_CENTER_URL,
  VITE_MEASUREMENT_ID: MEASUREMENT_ID,
  VITE_PAYMENT_ENROLLMENT_ENABLED: PAYMENT_ENROLLMENT_ENABLED = false,
} = parsedEnv;
