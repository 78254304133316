import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import useAuthenticatedUser from '@/api/hooks/useAuthenticateUser';
import useGetBusinessFacilityInformation from '@/api/hooks/useGetBusinessFacilityInformation';
import useUpdateFacilityInformation from '@/api/hooks/useUpdateFacilityInformation';

import InputDropdown from '@/components/Forms/InputDropdown';
import ControlBar from '@/components/UI/ControlBar';

import { CSS_VARS } from '@/constants/css-vars';
import { Router } from '@/constants/router';

import { useFacilitySectionData } from '@/pages/Facilities/hooks/useFacilitySectionData';

import { BANKING_ACTIVITY_SECTION_ID } from '../data';
import {
  ACCOUNT_TYPES,
  getNormalizedData,
  PRIMARY_PURPOSE_PAGE_ID,
  setPrimaryPurposeValues,
} from './data';

import useGetApplicationFeedbacks from '@/api/hooks/useGetApplicationFeedbacks';
import useGetBusiness from '@/api/hooks/useGetBusiness';
import useGetBusinessApplicationProgress from '@/api/hooks/useGetBusinessApplicationProgress';
import useGetBusinessValidation from '@/api/hooks/useGetBusinessValidation';
import { ApplicationStatusEnum } from '@/api/hooks/useGetBusinessValidation/type';
import useUpdateBusinessApplicationProgress from '@/api/hooks/useUpdateBusinessApplicationProgress';
import { FormSchema, formSchema, PRIMARY_PURPOSE_PROPERTIES } from './schema';
import { useOnboardingSections } from '@/utils/hooks/useOnboardingSections';
import { useOnboardingProgress } from '@/store/useOnboardingProgress';
import { useDiscardModal } from '@/utils/hooks/useDiscardModal';

function PrimaryPurpose(): JSX.Element {
  const { data: authenticatedUser } = useAuthenticatedUser();
  const { businesses } = authenticatedUser ?? {};
  const { id: businessId } = businesses?.[0] ?? {};
  const navigate = useNavigate();
  const { checkSectionCompleted } = useOnboardingSections();
  const isFacilityCompleted = checkSectionCompleted('facilities');
  const updateFacilityInformation = useUpdateFacilityInformation();
  const { isChangedTier } = useOnboardingProgress();
  const [initialValues, setInitialValues] = useState({});

  const [searchParams] = useSearchParams();
  const currentTier = searchParams.get('tier');
  const isTierTwo = currentTier === '2';
  const { data: business } = useGetBusiness({ businessId });

  const hasFinishedOnboard = business?.finishedOnboard;
  const shouldHandleNormal =
    !isFacilityCompleted || isChangedTier || hasFinishedOnboard;

  const { facilityId } = useFacilitySectionData({
    title: null,
    description: null,
    pageNumber: 15,
  });

  const updateBusinessApplicationProgress =
    useUpdateBusinessApplicationProgress();

  const {
    data: businessApplicationProgress,
    refetch: refetchBusinessProgress,
  } = useGetBusinessApplicationProgress(businessId ?? '', {
    enabled: businessId != null,
  });

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    register,
    formState: { errors },
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      [PRIMARY_PURPOSE_PROPERTIES.ACCOUNT_TYPE]: '',
    },
  });

  const { handleCancel, DiscardPopup } = useDiscardModal({
    oldData: initialValues,
    newData: watch(),
    onDiscard: () => {
      if (isTierTwo) {
        navigate(Router.Onboarding.Facilities.LocationReview.ActivityTierTwo);
      } else {
        navigate(Router.Onboarding.Facilities.LocationReview.ActivityTierOne);
      }
    },
  });

  const normalizedAccountTypeList = useMemo(
    () =>
      ACCOUNT_TYPES.map((accountType) => {
        return {
          id: accountType.id,
          label: accountType.label,
          value: accountType.id,
        };
      }),
    [],
  );
  const accountTypeValue = watch('account-type');
  const {
    data: businessFacilityInformation,
    isPending: isBusinessFacilityLoading,
  } = useGetBusinessFacilityInformation(
    businessId ?? '',
    facilityId ?? '',
    BANKING_ACTIVITY_SECTION_ID,
    PRIMARY_PURPOSE_PAGE_ID,
    { enabled: businessId != null && facilityId != null },
  );

  useEffect(() => {
    if (isBusinessFacilityLoading || businessFacilityInformation == null)
      return;
    setPrimaryPurposeValues(businessFacilityInformation, setValue);
    setInitialValues(getValues());
  }, [
    isBusinessFacilityLoading,
    businessFacilityInformation,
    setValue,
    getValues,
  ]);

  const isLoading = updateFacilityInformation.isPending;

  const { data: businessValidation } = useGetBusinessValidation(
    businessId ?? '',
    {
      enabled: businessId != null,
    },
  );

  const { data: applicationFeedbacks } = useGetApplicationFeedbacks(
    facilityId ?? '',
    {
      enabled:
        facilityId != null &&
        businessValidation?.status ===
          ApplicationStatusEnum.REVIEW_INFO_REQUESTED,
    },
  );

  const isApplicationResubmission =
    businessValidation?.status === ApplicationStatusEnum.REVIEW_INFO_REQUESTED;

  const onSubmitHandler = (formData, goNext?: boolean) => {
    if (businessId == null || facilityId == null) return;

    const sectionData = getNormalizedData(formData);
    updateFacilityInformation.mutate(
      {
        businessId,
        facilityId,
        section: sectionData,
      },
      {
        async onSuccess() {
          await updateBusinessApplicationProgress.mutateAsync(
            {
              businessId,
              pagesCompleted:
                businessFacilityInformation?.id !== '' ||
                authenticatedUser?.businesses?.[0]?.finishedOnboard
                  ? undefined
                  : (businessApplicationProgress?.pagesCompleted ?? 0) + 1,
            },
            {
              onSuccess() {
                refetchBusinessProgress().catch(console.error);
                if (!!shouldHandleNormal || goNext) {
                  const params = currentTier === '2' ? '?tier=2' : '';
                  navigate(
                    `${Router.Onboarding.Facilities.BankingActivity.BankingActivityDetail}${params}`,
                  );
                } else {
                  if (isTierTwo) {
                    navigate(
                      Router.Onboarding.Facilities.LocationReview
                        .ActivityTierTwo,
                    );
                  } else {
                    navigate(
                      Router.Onboarding.Facilities.LocationReview
                        .ActivityTierOne,
                    );
                  }
                }
              },
            },
          );
        },
      },
    );
  };

  const renderFooter = () => {
    const goBack = () => {
      if (shouldHandleNormal) {
        if (isTierTwo) {
          navigate(`${Router.Onboarding.Facilities.Details.Type}?tier=2`);
        } else {
          navigate(Router.Onboarding.Facilities.Details.Transportation);
        }
      } else {
        handleCancel();
      }
    };

    return (
      <ControlBar
        showInfoText={!hasFinishedOnboard && !isApplicationResubmission}
        backButtonProps={{
          text: shouldHandleNormal ? 'Back' : 'Go back to Review',
          onClick: goBack,
        }}
        nextButtonProps={{
          text: shouldHandleNormal ? 'Next' : 'Save',
          onClick: handleSubmit((data) => onSubmitHandler(data)),
          disabled:
            updateBusinessApplicationProgress.isPending ||
            updateFacilityInformation.isPending,
        }}
        continueButtonProps={{
          show: !shouldHandleNormal,
          disabled:
            updateBusinessApplicationProgress.isPending ||
            updateFacilityInformation.isPending,
          onClick: handleSubmit((data) => onSubmitHandler(data, true)),
        }}
        isLoading={isLoading}
      />
    );
  };

  return (
    <>
      <Typography gutterBottom variant="h2" sx={{ marginBottom: '32px' }}>
        What is the primary purpose for this account?{' '}
        <span style={{ color: `var(${CSS_VARS.Colors.Error.Dark})` }}>*</span>
      </Typography>

      <Grid sx={{ margin: '20px 5px' }}>
        <InputDropdown<FormSchema['account-type']>
          {...register('account-type')}
          fieldErrors={errors['account-type']}
          helperText={errors['account-type']?.message}
          options={normalizedAccountTypeList}
          value={accountTypeValue}
          placeholder="Select"
          showHelperText
          width={'43rem'}
          onChange={(event) => {
            const { value } = event.target;

            if (typeof value !== 'string') return;

            setValue(PRIMARY_PURPOSE_PROPERTIES.ACCOUNT_TYPE, value);
          }}
          disabled={
            businessValidation?.status ===
            ApplicationStatusEnum.REVIEW_INFO_REQUESTED
              ? !(applicationFeedbacks ?? []).filter(
                  (feedback) => feedback.section === 'account-type',
                ).length
              : false
          }
          errorFlag={
            businessValidation?.status ===
            ApplicationStatusEnum.REVIEW_INFO_REQUESTED
              ? !!(applicationFeedbacks ?? []).filter(
                  (feedback) => feedback.section === 'account-type',
                ).length
              : false
          }
        />
      </Grid>
      <Grid
        sx={{
          marginTop: '50px',
        }}
      >
        {renderFooter()}
        <DiscardPopup />
      </Grid>
    </>
  );
}

export default PrimaryPurpose;
