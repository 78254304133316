import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { StorageKeys } from '@/constants/misc';
import { Router, PublicRouter } from '@/constants/router';

import useAuthenticatedUser from '@/api/hooks/useAuthenticateUser';
import LoadingComponent from '@/components/UI/LoadingComponent';
import { Grid } from '@mui/material';
import useGetBusinessValidation from '@/api/hooks/useGetBusinessValidation';
import { useBusinessId } from '@/utils/hooks/useBusinessId';
import { ApplicationStatusEnum } from '@/api/hooks/useGetBusinessValidation/type';
import useLogout from '@/utils/hooks/useAuth/useLogout';

const PATHS_TO_NAVIGATE = {
  businessDetails: Router.Onboarding.BusinessDetails.About,
  // TODO: temporarilly remove these path
  // eligibility: '/eligibility',
  // notEligible: '/eligibility/status?sucess=false',
  successPage: '/review-and-sign/success',
  dashboard: Router.BankAccount.Dashboard,
};

function HomePage(): JSX.Element {
  const navigate = useNavigate();

  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    error,
  } = useAuth0();

  const { logout } = useLogout();

  const [startUserAuthentication, setStartUserAuthentication] = useState(false);

  const { data: authenticatedUserData, isPending: isAuthenticatingUser } =
    useAuthenticatedUser({
      enabled: startUserAuthentication,
    });

  const isUserAuthed = !isLoading && isAuthenticated;

  const businessId = useBusinessId(isUserAuthed);

  const { data: businessValidation } = useGetBusinessValidation(
    businessId ?? '',
    {
      enabled: businessId != null,
    },
  );
  // const isUserEligible = authenticatedUserData?.isEligible;

  const isNew = !authenticatedUserData?.businesses?.length;

  useEffect(() => {
    if (!isUserAuthed) return;

    const getUserToken = async (): Promise<void> => {
      const userToken = await getAccessTokenSilently();

      localStorage.setItem(StorageKeys.Token, userToken);

      setStartUserAuthentication(true);
    };

    getUserToken().catch(console.error);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthed]);

  useEffect(() => {
    if (!startUserAuthentication || isAuthenticatingUser) return;
    let pathToNavigate;

    // TODO temporarily remove
    // if (isUserEligible == null) {
    //   navigate(PATHS_TO_NAVIGATE.eligibility, { replace: true });
    //   return;
    // }
    // if (!isUserEligible) {
    //   pathToNavigate = PATHS_TO_NAVIGATE.notEligible;
    //   return navigate(pathToNavigate, { replace: true });
    // }
    if (isNew) {
      navigate(Router.Onboarding.BusinessDetails.RequiredDocuments);
      return;
    }
    if (!businessValidation) return;

    if (
      // isUserEligible &&
      businessValidation.status === ApplicationStatusEnum.REVIEW_INFO_REQUESTED
    ) {
      pathToNavigate = PATHS_TO_NAVIGATE.successPage;
      // DEV mode
      // pathToNavigate = PATHS_TO_NAVIGATE.businessDetails;
    } else if (businessValidation.status === ApplicationStatusEnum.APPROVED) {
      pathToNavigate = PATHS_TO_NAVIGATE.dashboard;
    } else {
      pathToNavigate = PATHS_TO_NAVIGATE.businessDetails;
    }

    navigate(pathToNavigate, { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticatingUser,
    // TODO temporarily remove
    // isUserEligible,
    startUserAuthentication,
    businessValidation,
  ]);

  const onLoginWithRedirect = (): void => {
    void loginWithRedirect();
  };

  useEffect(() => {
    if (!isAuthenticated && !isLoading && !error) {
      onLoginWithRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, error]);

  useEffect(() => {
    const msg = error?.message;

    if (msg?.includes('ExpiredPassword')) {
      const email = msg?.split?.('$')?.[1] ?? '';
      const url = `${PublicRouter.ExpiredPassword}?email=${encodeURIComponent(
        email,
      )}`;

      logout(email && url);
    }

    if (msg?.includes('ExistedAccount')) {
      const info = msg?.split?.('#') || [];
      const email = info?.[1] ?? '';
      const connectionType = info?.[2] ?? '';
      const url = `${PublicRouter.ExistedAccount}?email=${encodeURIComponent(
        email,
      )}&type=${connectionType}`;

      logout(email && url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: '100vh' }}
    >
      <Grid item>
        <LoadingComponent />
      </Grid>
    </Grid>
  );
}

export default HomePage;
