import useAuthenticatedUser from '@/api/hooks/useAuthenticateUser';
import useGetApplicationFeedbacks from '@/api/hooks/useGetApplicationFeedbacks';
import useGetBusiness from '@/api/hooks/useGetBusiness';
import useGetBusinessApplicationProgress from '@/api/hooks/useGetBusinessApplicationProgress';
import useGetBusinessDocuments from '@/api/hooks/useGetBusinessDocuments';
import useGetBusinessFacilityInformation from '@/api/hooks/useGetBusinessFacilityInformation';
import useGetBusinessValidation from '@/api/hooks/useGetBusinessValidation';
import { ApplicationStatusEnum } from '@/api/hooks/useGetBusinessValidation/type';
import useUpdateBusinessApplicationProgress from '@/api/hooks/useUpdateBusinessApplicationProgress';
import useUpdateFacilityInformation from '@/api/hooks/useUpdateFacilityInformation';
import { FIXED_COUNTRY } from '@/constants/misc';
import { Router } from '@/constants/router';
import type { StatesEmpty, US_STATES } from '@/constants/us-states';
import type { BusinessDocumentType } from '@/types/business';
import AddressForm from '@components/Forms/Address/AddressForm';
import type { AddressFormSchema } from '@components/Forms/Address/AddressForm/type';
import MailingAddressForm from '@components/Forms/Address/MailingAddressForm';
import type { MailingAddressFormSchema } from '@components/Forms/Address/MailingAddressForm/type';
import type { CustomRegister } from '@components/Forms/Address/types';
import Checkbox from '@components/Forms/Checkbox';
import ControlBar from '@components/UI/ControlBar';
import { zodResolver } from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import type { SelectChangeEvent } from '@mui/material/Select';
import {
  AddressesFormSchema,
  facilityAddressWithMailingFormSchema,
  facilityAddressWithOutMailingFormSchema,
} from '@pages/Facilities/Sections/Details/Address/schema';
import { BUSINESS_DETAILS_SECTION_ID } from '@pages/Facilities/Sections/Details/types';
import { getFacilityDocumentItem } from '@pages/Facilities/Sections/facilityUtility';
import { useFacilitySectionData } from '@pages/Facilities/hooks/useFacilitySectionData';
import { useEffect, useState } from 'react';
import { useForm, UseFormWatch, UseFormSetValue } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import {
  ADDRESS_PAGE_ID,
  getNormalizedData,
  setFacilityAddressValues,
} from './data';
import { DETAIL_TYPE_PAGE_ID, DetailTierType } from '../Type/data';
import { useOnboardingSections } from '@/utils/hooks/useOnboardingSections';
import { useOnboardingProgress } from '@/store/useOnboardingProgress';
import { useDiscardModal } from '@/utils/hooks/useDiscardModal';

type DocumentType = Extract<BusinessDocumentType, 'property_title'>;

const DOCUMENT_TYPE: DocumentType = 'property_title';

function FacilityAddress(): JSX.Element {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] =
    useState<AddressesFormSchema | null>(null);

  const { isChangedTier } = useOnboardingProgress();
  const { checkSectionCompleted } = useOnboardingSections();
  const isFacilityCompleted = checkSectionCompleted('facilities');
  const [isMailingAddress, setIsMailingAddress] = useState(false);
  const [crrTier, setCrrTier] = useState('');

  const { data: authenticatedUser } = useAuthenticatedUser();

  const { businesses } = authenticatedUser ?? {};
  const { id: businessId } = businesses?.[0] ?? {};
  const { facilityId } = useFacilitySectionData();
  const updateFacilityInformation = useUpdateFacilityInformation();
  const { data: business } = useGetBusiness({ businessId });

  const hasFinishedOnboard = business?.finishedOnboard;
  const shouldHandleNormal =
    !isFacilityCompleted || isChangedTier || hasFinishedOnboard;

  const updateBusinessApplicationProgress =
    useUpdateBusinessApplicationProgress();

  const {
    data: businessApplicationProgress,
    refetch: refetchBusinessProgress,
  } = useGetBusinessApplicationProgress(businessId ?? '', {
    enabled: businessId != null,
  });

  const {
    data: businessFacilityInformation,
    isPending: isBusinessFacilityLoading,
  } = useGetBusinessFacilityInformation(
    businessId ?? '',
    facilityId ?? '',
    BUSINESS_DETAILS_SECTION_ID,
    ADDRESS_PAGE_ID,
    { enabled: businessId != null && facilityId != null },
  );

  const {
    data: businessFacilityDetail,
    isPending: isBusinessFacilityDetailLoading,
  } = useGetBusinessFacilityInformation(
    businessId ?? '',
    facilityId ?? '',
    BUSINESS_DETAILS_SECTION_ID,
    DETAIL_TYPE_PAGE_ID,
    { enabled: businessId != null && facilityId != null },
  );

  const [usStateAddress, setUsStateAddress] = useState<StatesEmpty>('');

  const [usStateMailingAddress, setUsStateMailingAddress] =
    useState<StatesEmpty>('');

  const handleStateAddressChange = (data: SelectChangeEvent<unknown>): void => {
    const state = data.target.value as (typeof US_STATES)[number];
    setValue('state', state);
    setUsStateAddress(state);
  };

  const handleMailingStateAddressChange = (
    data: SelectChangeEvent<unknown>,
  ): void => {
    const state = data.target.value as (typeof US_STATES)[number];
    setValue('mailing-state', state);
    setUsStateMailingAddress(state);
  };

  const { data: documentList } = useGetBusinessDocuments<DocumentType>(
    businessId ?? '',
    facilityId ?? '',
    '',
    {
      enabled: businessId != null && facilityId != null,
    },
  );

  const { data: businessValidation } = useGetBusinessValidation(
    businessId ?? '',
    {
      enabled: businessId != null,
    },
  );

  const { data: applicationFeedbacks } = useGetApplicationFeedbacks(
    facilityId ?? '',
    {
      enabled:
        facilityId != null &&
        businessValidation?.status ===
          ApplicationStatusEnum.REVIEW_INFO_REQUESTED,
    },
  );

  const propertyTitleDocument = documentList?.find(
    (document) => document.documentType === DOCUMENT_TYPE,
  );

  const getValidationSchema = z.lazy(() =>
    isMailingAddress
      ? facilityAddressWithMailingFormSchema
      : facilityAddressWithOutMailingFormSchema,
  );

  const { setTitle, facilityName } = useFacilitySectionData({
    description: 'Physical Address for This Location',
    pageNumber: 9,
  });

  useEffect(() => {
    if (typeof facilityName !== 'string') return;
    setTitle(`Tell us about ${facilityName}.`);
  }, [facilityName, setTitle]);

  const {
    getValues,
    setValue,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<AddressesFormSchema>({
    resolver: zodResolver(getValidationSchema),
    defaultValues: {
      country: FIXED_COUNTRY,
      'street-address': '',
      'unit-number': '',
      city: '',
      state: undefined,
      'postal-code': '',
      'mailing-street-address': '',
      'mailing-unit-number': '',
      'mailing-city': '',
      'mailing-state': null,
      'mailing-postal-code': '',
      'mailing-country': FIXED_COUNTRY,
    },
  });

  const { handleCancel, DiscardPopup } = useDiscardModal({
    oldData: initialValues,
    newData: watch(),
    onDiscard: () =>
      navigate(Router.Onboarding.Facilities.LocationReview.ActivityTierOne),
  });

  useEffect(() => {
    if (isBusinessFacilityLoading || businessFacilityInformation == null)
      return;

    setFacilityAddressValues(businessFacilityInformation, setValue);

    const mailingAddress = businessFacilityInformation.page.items.find(
      (item) => item.id === 'mailing-street-address',
    );
    setUsStateAddress(getValues('state') ?? '');

    setIsMailingAddress(mailingAddress != null);
    if (mailingAddress != null) {
      setUsStateMailingAddress(
        getValues('mailing-state') as (typeof US_STATES)[number],
      );
    }

    setInitialValues(getValues());
  }, [
    isBusinessFacilityLoading,
    businessFacilityInformation,
    setValue,
    getValues,
  ]);

  const isLoading = updateFacilityInformation.isPending;

  const isApplicationResubmission =
    businessValidation?.status === ApplicationStatusEnum.REVIEW_INFO_REQUESTED;

  const onSubmitHandler = (facilityData, goNext?: boolean) => {
    if (businessId == null || facilityId == null) return;
    const sectionData = getNormalizedData(facilityData, isMailingAddress);

    if (propertyTitleDocument != null) {
      const label = 'Property Title or Lease Contract';
      const id = 'property_title_or_lease_contract';
      sectionData.page.items.push(
        getFacilityDocumentItem(
          label,
          id,
          propertyTitleDocument.syncteraDocumentId,
        ),
      );
    }

    updateFacilityInformation.mutate(
      {
        businessId,
        facilityId,
        section: sectionData,
      },
      {
        async onSuccess() {
          await updateBusinessApplicationProgress.mutateAsync(
            {
              businessId,
              pagesCompleted:
                businessFacilityInformation?.id !== '' ||
                authenticatedUser?.businesses?.[0]?.finishedOnboard
                  ? undefined
                  : (businessApplicationProgress?.pagesCompleted ?? 0) + 1,
            },
            {
              onSuccess() {
                refetchBusinessProgress().catch(console.error);
                if (!!shouldHandleNormal || goNext) {
                  if (crrTier === DetailTierType.TIER_2.id) {
                    navigate(Router.Onboarding.Facilities.Details.Products);
                  } else {
                    navigate(
                      Router.Onboarding.Facilities.Details.SoftwareLicense,
                    );
                  }
                } else {
                  navigate(
                    Router.Onboarding.Facilities.LocationReview.ActivityTierOne,
                  );
                }
              },
            },
          );
        },
      },
    );
  };

  useEffect(() => {
    if (isBusinessFacilityDetailLoading || businessFacilityDetail == null)
      return;

    const secondTier = businessFacilityDetail.page.items.find(
      (i) => i.id === DetailTierType.TIER_2.id,
    );

    if (secondTier) {
      setCrrTier(DetailTierType.TIER_2.id);
    } else {
      setCrrTier(DetailTierType.TIER_1.id);
    }
  }, [isBusinessFacilityDetailLoading, businessFacilityDetail, setCrrTier]);

  const handleMailingAddressCheckboxChange = (): void => {
    setIsMailingAddress((currentState) => !currentState);
  };

  const renderFooter = () => {
    const goBack = () => {
      if (shouldHandleNormal) {
        navigate(Router.Onboarding.Facilities.Details.Type);
      } else {
        handleCancel();
      }
    };

    return (
      <ControlBar
        showInfoText={!hasFinishedOnboard && !isApplicationResubmission}
        backButtonProps={{
          text: shouldHandleNormal ? 'Back' : 'Go back to Review',
          onClick: goBack,
        }}
        nextButtonProps={{
          text: shouldHandleNormal ? 'Next' : 'Save',
          disabled:
            updateBusinessApplicationProgress.isPending ||
            updateFacilityInformation.isPending ||
            isBusinessFacilityLoading,
          onClick: handleSubmit((data) => onSubmitHandler(data)),
        }}
        continueButtonProps={{
          show: !shouldHandleNormal,
          disabled:
            updateBusinessApplicationProgress.isPending ||
            updateFacilityInformation.isPending ||
            isBusinessFacilityLoading,
          onClick: handleSubmit((data) => onSubmitHandler(data, true)),
        }}
        isLoading={isLoading}
      />
    );
  };

  return (
    <>
      <AddressForm
        watch={watch as UseFormWatch<any>}
        setValue={setValue as UseFormSetValue<any>}
        stateAddress={usStateAddress}
        handleAddressChange={handleStateAddressChange}
        errors={errors}
        register={register as CustomRegister<AddressFormSchema>}
        feedbackFields={applicationFeedbacks ?? []}
        isUnderReview={
          businessValidation?.status ===
          ApplicationStatusEnum.REVIEW_INFO_REQUESTED
        }
      />
      <Box
        sx={{
          marginBottom: '2rem',
        }}
      >
        <Checkbox
          checked={isMailingAddress}
          label="Mailing address is different"
          sx={{
            color: '#254d73',
            '&.Mui-checked': {
              color: '#254d73',
            },
          }}
          onChange={handleMailingAddressCheckboxChange}
          disabled={
            businessValidation?.status ===
            ApplicationStatusEnum.REVIEW_INFO_REQUESTED
          }
        />
      </Box>

      {isMailingAddress && (
        <MailingAddressForm
          watch={watch as UseFormWatch<any>}
          setValue={setValue as UseFormSetValue<any>}
          stateMailingAddress={usStateMailingAddress}
          handleMailingStateAddressChange={handleMailingStateAddressChange}
          errors={errors}
          register={register as CustomRegister<MailingAddressFormSchema>}
          feedbackFields={applicationFeedbacks ?? []}
          isUnderReview={
            businessValidation?.status ===
            ApplicationStatusEnum.REVIEW_INFO_REQUESTED
          }
        />
      )}

      {renderFooter()}

      <DiscardPopup />
    </>
  );
}

export default FacilityAddress;
