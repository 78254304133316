/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect } from 'react';
import Router, { PublicRouter } from '@components/Router';
import { useSentry } from '@/utils/useSentry';
import { useLocation } from 'react-router-dom';
import { MEASUREMENT_ID } from '@/constants/env';
import Debugger from '@/components/Debugger';

import ReactGA from 'react-ga4';

ReactGA.initialize(MEASUREMENT_ID);

function App(): JSX.Element {
  useSentry();
  const location = useLocation();
  const isPublic = location.pathname.startsWith('/public');

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: 'Paybotic Page',
    });
  }, [location.pathname, location.search]);

  return (
    <>
      {isPublic ? <PublicRouter /> : <Router />}
      <Debugger />
    </>
  );
}

export default App;
