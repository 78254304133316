import type { FC } from 'react';
import List from '@mui/material/List';
import { useLocation } from 'react-router-dom';
import type { LocationNavItem } from '../../data';
import LocationNavButton from '../LocationNavButton';
import NavCollapse from '../NavCollapse';
interface IProps {
  list: LocationNavItem[];
  toggleMobileSidebarOpen?: () => void;
}

const LocationNavIterator: FC<IProps> = ({
  list,
  toggleMobileSidebarOpen,
}): JSX.Element => {
  const location = useLocation();

  return (
    <nav>
      <List disablePadding>
        {list.map((listItem, index) => {
          if (listItem.children) {
            return (
              <NavCollapse
                key={index}
                navItem={listItem}
                toggleMobileSidebarOpen={toggleMobileSidebarOpen}
              />
            );
          }

          return (
            <LocationNavButton
              {...listItem}
              key={index}
              isSelected={location.pathname === listItem.path}
              onClick={() => toggleMobileSidebarOpen?.()}
            />
          );
        })}
      </List>
    </nav>
  );
};

export default LocationNavIterator;
