import { useCallback, useEffect } from 'react';
import { useIsFirstRender } from '@/utils/hooks/useIsFirstRender';

import { useFacilitiesState } from '@/features/ui/facilities';

const useGetFacilitySectionData = (): {
  facilityIdRef: string | null;
  facilityNameRef: string | null;
  sectionDescriptionRef: string | null;
  sectionTitleRef: string | null;
  pageNumberRef: number | null;
  stateIssuedRequiredRef: boolean;
} => {
  const facilityIdRef = useFacilitiesState((state) => state.facilityId);

  const facilityNameRef = useFacilitiesState((state) => state.facilityName);

  const sectionDescriptionRef = useFacilitiesState(
    (state) => state.sectionDescription,
  );

  const sectionTitleRef = useFacilitiesState((state) => state.sectionTitle);

  const pageNumberRef = useFacilitiesState((state) => state.pageNumber);

  const stateIssuedRequiredRef = useFacilitiesState(
    (state) => state.stateIssuedRequired,
  );

  return {
    facilityIdRef,
    facilityNameRef,
    sectionDescriptionRef,
    sectionTitleRef,
    pageNumberRef,
    stateIssuedRequiredRef,
  };
};

const useSetFacilitySectionData = (): {
  setDescription: (sectionDescription: string | null) => void;
  setFacilityId: (facilityId: string | null) => void;
  setTitle: (sectionTitle: string | null) => void;
  setFacilityName: (facilityName: string | null) => void;
  setPageNumber: (pageNumber: number | null) => void;
  setStateIssuedRequired: (stateIssuedRequired: boolean) => void;
} => {
  const updateFacilityId = useFacilitiesState(
    (state) => state.updateFacilityId,
  );

  const updateSectionDescription = useFacilitiesState(
    (state) => state.updateSectionDescription,
  );

  const updateSectionTitle = useFacilitiesState(
    (state) => state.updateSectionTitle,
  );

  const updateFacilityName = useFacilitiesState(
    (state) => state.updateFacilityName,
  );

  const updateStateIssuedRequired = useFacilitiesState(
    (state) => state.updateStateIssuedRequired,
  );

  const updatePageNumber = useFacilitiesState(
    (state) => state.updatePageNumber,
  );

  const setFacilityName = useCallback<typeof updateFacilityName>(
    (description) => {
      updateFacilityName(description);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const setDescription = useCallback<typeof updateSectionDescription>(
    (description) => {
      updateSectionDescription(description);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const setFacilityId = useCallback<typeof updateFacilityId>((facilityId) => {
    updateFacilityId(facilityId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setTitle = useCallback<typeof updateSectionTitle>((title) => {
    updateSectionTitle(title);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPageNumber = useCallback<typeof updatePageNumber>((pageNumber) => {
    updatePageNumber(pageNumber);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setStateIssuedRequired = useCallback<typeof updateStateIssuedRequired>(
    (stateIssuedRequired) => {
      updateStateIssuedRequired(stateIssuedRequired);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    setDescription,
    setFacilityId,
    setTitle,
    setFacilityName,
    setPageNumber,
    setStateIssuedRequired,
  };
};

interface UseFacilitySectionDataProps {
  description?: string | null;
  title?: string | null;
  pageNumber?: number | null;
}

export const useFacilitySectionData = (
  initialFacilityStateProps?: UseFacilitySectionDataProps,
): {
  setDescription: (sectionDescription: string | null) => void;
  setFacilityId: (facilityId: string | null) => void;
  setTitle: (sectionTitle: string | null) => void;
  setFacilityName: (facilityName: string | null) => void;
  setPageNumber: (pageNumber: number | null) => void;
  setStateIssuedRequired: (stateIssuedRequired: boolean) => void;
  description: string | null;
  facilityId: string | null;
  facilityName: string | null;
  title: string | null;
  pageNumber: number | null;
  stateIssuedRequired: boolean;
} => {
  const { description, title, pageNumber } = initialFacilityStateProps ?? {};

  const isFirstRender = useIsFirstRender();

  const {
    facilityIdRef,
    facilityNameRef,
    sectionDescriptionRef,
    sectionTitleRef,
    pageNumberRef,
    stateIssuedRequiredRef,
  } = useGetFacilitySectionData();

  const setFacilitySectionData = useSetFacilitySectionData();

  useEffect(() => {
    if (!isFirstRender || title === undefined) return;

    setFacilitySectionData.setTitle(title ?? null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstRender, title]);

  useEffect(() => {
    if (!isFirstRender || description === undefined) return;

    setFacilitySectionData.setDescription(description ?? null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description, isFirstRender]);

  useEffect(() => {
    if (!isFirstRender) return;

    setFacilitySectionData.setPageNumber(pageNumber ?? null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstRender, pageNumber]);

  return {
    description: sectionDescriptionRef,
    facilityId: facilityIdRef,
    facilityName: facilityNameRef,
    title: sectionTitleRef,
    pageNumber: pageNumberRef,
    stateIssuedRequired: stateIssuedRequiredRef,
    ...setFacilitySectionData,
  };
};
