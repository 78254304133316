import { BankAccountRoutes } from '@/constants/router';

import type { SystemUserAuthResponse } from '@/types/user';
import { nanoid } from 'nanoid';
import { lazy } from 'react';

const LazyAccountOverview = lazy(
  async () => await import('@pages/BankAccount/AccountOverview'),
);

const LazyAccountDashboard = lazy(
  async () => await import('@pages/BankAccount/Dashboard'),
);

const LazyAuthorizedUsers = lazy(
  async () => await import('@pages/BankAccount/AuthorizedUsers'),
);

const LazyDebitLimits = lazy(
  async () => await import('@pages/BankAccount/DebitLimits'),
);

const LazySystemUsers = lazy(
  async () => await import('@pages/BankAccount/SystemUsers'),
);

const LazySystemRoles = lazy(
  async () => await import('@pages/BankAccount/SystemRoles'),
);

const LazyPaymentsAndPayees = lazy(
  async () => await import('@pages/BankAccount/PaymentsAndPayees/Payees'),
);

const LazyManageScheduledPayments = lazy(
  async () =>
    await import(
      '@/pages/BankAccount/PaymentsAndPayees/ManageScheduledPayments'
    ),
);

const LazyPayment = lazy(
  async () =>
    await import('@pages/BankAccount/PaymentsAndPayees/Payments/Payment'),
);

const LazyReview = lazy(
  async () =>
    await import('@pages/BankAccount/PaymentsAndPayees/Payments/Review'),
);

const LazyDepositCheck = lazy(
  async () =>
    await import(
      '@pages/BankAccount/PaymentsAndPayees/Payments/DepositCheck/index'
    ),
);

const LazyCompleteDepositCheck = lazy(
  async () =>
    await import(
      '@pages/BankAccount/PaymentsAndPayees/Payments/DepositCheck/CompleteDepositCheck'
    ),
);
const LazyPaymentSuccess = lazy(
  async () =>
    await import(
      '@pages/BankAccount/PaymentsAndPayees/Payments/components/Success'
    ),
);

const LazyBusinessDetails = lazy(
  async () => await import('@/pages/BankAccount/BusinessDetails'),
);

const LazyLocationDetails = lazy(
  async () => await import('@/pages/BankAccount/LocationDetails'),
);

const LazyReports = lazy(
  async () => await import('@/pages/BankAccount/Reports'),
);

const LazyPaymentSystemEnrollment = lazy(
  async () => await import('@/pages/BankAccount/PaymentSystemEnrollment'),
);

const LazyRequestCash = lazy(
  async () => await import('@/pages/BankAccount/RequestCash'),
);

const LazyRequestCashComplete = lazy(
  async () => await import('@/pages/BankAccount/RequestCash/RequestCashCheck'),
);

interface IBankAccountComponent {
  id: string;
  path?: string;
  component?: (prop?: SystemUserAuthResponse) => JSX.Element;
  children?: IBankAccountComponent[];
}

interface IBankAccountComponents {
  SystemUsers: IBankAccountComponent;
  Dashboard: IBankAccountComponent;
  Overview: IBankAccountComponent;
  AuthorizedUsers: IBankAccountComponent;
  PaymentsAndPayees: IBankAccountComponent;
  ManageScheduledPayments: IBankAccountComponent;
  PayAch: IBankAccountComponent;
  PayWire: IBankAccountComponent;
  SuccessAch: IBankAccountComponent;
  SuccessWire: IBankAccountComponent;
  ReviewAch: IBankAccountComponent;
  ReviewWire: IBankAccountComponent;
  DepositCheck: IBankAccountComponent;
  ReviewDepositCheck: IBankAccountComponent;
  BusinessDetails: IBankAccountComponent;
  LocationDetails: IBankAccountComponent;
  Reports: IBankAccountComponent;
  RequestCash: IBankAccountComponent;
  RequestCashComplete: IBankAccountComponent;
  PaymentSystemEnrollment: IBankAccountComponent;
}

export const BankAccountComponents: IBankAccountComponents = {
  Dashboard: {
    id: nanoid(),
    path: BankAccountRoutes.Dashboard,
    component: (prop) => <LazyAccountDashboard systemUser={prop} />,
  },
  Overview: {
    id: nanoid(),
    path: BankAccountRoutes.Overview,
    component: () => <LazyAccountOverview />,
  },
  SystemUsers: {
    id: nanoid(),
    children: [
      {
        id: nanoid(),
        path: BankAccountRoutes.SystemRoleType,
        component: () => <LazySystemRoles />,
      },
      {
        id: nanoid(),
        path: BankAccountRoutes.SystemUsers,
        component: (prop) => <LazySystemUsers systemUser={prop} />,
      },
    ],
  },
  AuthorizedUsers: {
    id: nanoid(),
    children: [
      {
        id: nanoid(),
        path: BankAccountRoutes.DebitLimits,
        component: () => <LazyDebitLimits />,
      },
      {
        id: nanoid(),
        path: BankAccountRoutes.Cardholders,
        component: () => <LazyAuthorizedUsers />,
      },
    ],
  },
  PaymentsAndPayees: {
    id: nanoid(),
    path: BankAccountRoutes.PaymentsAndPayees,
    component: () => <LazyPaymentsAndPayees />,
  },
  ManageScheduledPayments: {
    id: nanoid(),
    path: BankAccountRoutes.ManageScheduledPayments,
    component: () => <LazyManageScheduledPayments />,
  },
  PayAch: {
    id: nanoid(),
    path: BankAccountRoutes.PayAch,
    component: () => <LazyPayment paymentType="ACH" />,
  },
  PayWire: {
    id: nanoid(),
    path: BankAccountRoutes.PayWire,
    component: () => <LazyPayment paymentType="Wire" />,
  },
  SuccessAch: {
    id: nanoid(),
    path: BankAccountRoutes.SuccessAch,
    component: () => <LazyPaymentSuccess paymentType="ACH" />,
  },
  SuccessWire: {
    id: nanoid(),
    path: BankAccountRoutes.SuccessWire,
    component: () => <LazyPaymentSuccess paymentType="Wire" />,
  },
  ReviewAch: {
    id: nanoid(),
    path: BankAccountRoutes.ReviewAch,
    component: () => <LazyReview transferType="ACH" />,
  },
  ReviewWire: {
    id: nanoid(),
    path: BankAccountRoutes.ReviewWire,
    component: () => <LazyReview transferType="Wire" />,
  },
  DepositCheck: {
    id: nanoid(),
    path: BankAccountRoutes.DepositCheck,
    component: () => <LazyDepositCheck />,
  },
  ReviewDepositCheck: {
    id: nanoid(),
    path: BankAccountRoutes.CompleteDepositCheck,
    component: () => <LazyCompleteDepositCheck />,
  },
  BusinessDetails: {
    id: nanoid(),
    path: BankAccountRoutes.BusinessDetails,
    component: () => <LazyBusinessDetails />,
  },
  LocationDetails: {
    id: nanoid(),
    path: BankAccountRoutes.LocationDetails,
    component: () => <LazyLocationDetails />,
  },
  Reports: {
    id: nanoid(),
    path: BankAccountRoutes.Reports,
    component: () => <LazyReports />,
  },
  RequestCash: {
    id: nanoid(),
    path: BankAccountRoutes.RequestCash,
    component: () => <LazyRequestCash />,
  },
  RequestCashComplete: {
    id: nanoid(),
    path: BankAccountRoutes.RequestCashComplete,
    component: () => <LazyRequestCashComplete />,
  },
  PaymentSystemEnrollment: {
    id: nanoid(),
    path: BankAccountRoutes.PaymentSystemEnrollment,
    component: () => <LazyPaymentSystemEnrollment />,
  },
};
