import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { Router } from '@/constants/router';
import { NavigationItem, navigationStep } from '@/features/ui/navigation';

export type OnboardingStep = Record<string, NavigationItem>;

interface NavigationState {
  isChangedTier: boolean;
  shouldGoBusinessDetailReview: boolean;
  currentFacilityPath: string;
  onboardingSteps: OnboardingStep;
  setCurrentFacilityPath: (path: string) => void;
  setOnboardingStep: (steps: OnboardingStep) => void;
  setShouldGoBusinessDetailReview: (
    shouldGoBusinessDetailReview: boolean,
  ) => void;
  setIsChangedTier: (isChangedTier: boolean) => void;
}

export const useOnboardingProgress = create<NavigationState>()(
  devtools(
    persist(
      (set) => ({
        isChangedTier: false,
        shouldGoBusinessDetailReview: false,
        currentFacilityPath:
          Router.Onboarding.FacilitiesLocations.LicenseRequisite,
        onboardingSteps: {
          [navigationStep.BusinessDetails]: {
            id: navigationStep.BusinessDetails,
            path: Router.Onboarding.BusinessDetails.About,
            text: 'Business Details',
            isCompleted: false,
          },
          [navigationStep.BusinessOperatorDetails]: {
            id: navigationStep.BusinessOperatorDetails,
            path: Router.Onboarding.BusinessOperatorDetails.Details,
            text: 'Business Operator Details',
            isCompleted: false,
          },
          [navigationStep.BusinessOwnership]: {
            id: navigationStep.BusinessOwnership,
            path: Router.Onboarding.BusinessOwnership.Start,
            text: 'Business Ownership',
            isCompleted: false,
          },
          [navigationStep.Facilities]: {
            id: navigationStep.Facilities,
            path: Router.Onboarding.FacilitiesLocations.LicenseRequisite,
            text: 'Facilities',
            isCompleted: false,
          },
          [navigationStep.BusinessDocuments]: {
            id: navigationStep.BusinessDocuments,
            path: Router.Onboarding.BusinessDocuments.Upload,
            text: 'Business Documents',
            isCompleted: false,
          },
          [navigationStep.ReviewAndSign]: {
            id: navigationStep.ReviewAndSign,
            path: Router.Onboarding.ReviewAndSign.Submit,
            text: 'Review & Sign',
            isCompleted: false,
          },
        },
        setOnboardingStep: (updateSteps) =>
          set((state) => ({
            onboardingSteps: { ...state.onboardingSteps, ...updateSteps },
          })),
        setShouldGoBusinessDetailReview: (shouldGoBusinessDetailReview) =>
          set({ shouldGoBusinessDetailReview }),
        setIsChangedTier: (isChangedTier) => set({ isChangedTier }),
        setCurrentFacilityPath: (currentFacilityPath) =>
          set({ currentFacilityPath }),
      }),
      {
        name: 'onboarding-steps',
        getStorage: () => localStorage,
      },
    ),
  ),
);
