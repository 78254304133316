import { useReducer } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import _keyBy from 'lodash/keyBy';

import Footer from '@/components/Footer';
import Header from '@/components/Header';
import OnboardingNavigation from '@/components/Sidebar/OnboardingNavigation';
import BankAccountNavigation from '@/components/Sidebar/BankAccountNavigation';

import useGetBusiness from '@/api/hooks/useGetBusiness';
import type { NavigationItem } from '@/features/ui/navigation';
import { useBusinessId } from '@/utils/hooks/useBusinessId';
import useGetSystemRoles from '@/api/hooks/useGetSystemRoles';
import { TSystemRoles, useSystemRoles } from '@/store/useSystemRoles';

interface WithSidebar {
  hasSidebar: true;
  navitems: NavigationItem[];
}

interface WithoutSidebar {
  hasSidebar?: never;
}

type BaseLayoutProps = {
  children: React.ReactNode;
  centerContent?: boolean;
  hasHeader?: boolean;
  isBlock?: boolean;
} & (WithSidebar | WithoutSidebar);

const BaseLayout = (props: BaseLayoutProps): JSX.Element => {
  const { children, centerContent, hasHeader, hasSidebar, isBlock } = props;

  const [mobileSidebarOpen, toggleMobileSidebarOpen] = useReducer(
    (s) => !s,
    false,
  );

  useGetSystemRoles({
    enabled: true,
    onSuccess(data) {
      const systemRoles = _keyBy(data, 'type') as TSystemRoles;

      useSystemRoles.setState({ systemRoles });
    },
  });

  const theme = useTheme();
  const isDesktopMode = useMediaQuery(theme.breakpoints.up('md'));

  if (isDesktopMode && mobileSidebarOpen) {
    toggleMobileSidebarOpen();
  }

  const businessId = useBusinessId();

  const { data: business } = useGetBusiness({ businessId });

  let gridAreas = '';

  if (hasHeader) {
    gridAreas += hasSidebar ? "'header header'" : "'header'";
  }

  gridAreas += hasSidebar ? "'sidebar content'" : "'content'";

  gridAreas += hasSidebar ? "'sidebar footer'" : "'footer'";

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateAreas: {
          xs: "'header' 'content' 'footer'",
          md: gridAreas,
        },
        gridTemplateColumns: {
          xs: 'minmax(0, 1fr)',
          md: hasSidebar ? 'auto 1fr' : '1fr',
        },
        gridTemplateRows: hasHeader ? 'auto 1fr' : '1fr',
        minHeight: '100vh',
      }}
    >
      {hasHeader && (
        <Header
          mobileSidebarOpener={hasSidebar ? toggleMobileSidebarOpen : undefined}
        />
      )}

      {hasSidebar && business?.finishedOnboard ? (
        <OnboardingNavigation navItemList={props.navitems} />
      ) : hasSidebar ? (
        <BankAccountNavigation navItemList={props.navitems} />
      ) : null}

      {hasSidebar && (
        <Drawer
          variant="temporary"
          open={mobileSidebarOpen}
          onClose={toggleMobileSidebarOpen}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: {
              xs: 'block',
              md: 'none',
            },
            aside: {
              display: {
                xs: 'flex',
                md: 'none',
              },
            },
          }}
        >
          <Box>
            {business?.finishedOnboard ? (
              <OnboardingNavigation
                navItemList={props.navitems}
                toggleMobileSidebarOpen={toggleMobileSidebarOpen}
              />
            ) : (
              <BankAccountNavigation
                navItemList={props.navitems}
                toggleMobileSidebarOpen={toggleMobileSidebarOpen}
              />
            )}
          </Box>
        </Drawer>
      )}

      <Box
        component="main"
        sx={{
          minWidth: 0,
          gridArea: 'content',
          marginLeft: { xs: 0, md: centerContent ? 'auto' : undefined },
          marginRight: { xs: 0, md: centerContent ? 'auto' : undefined },
          width: { xs: '100%', lg: isBlock ? '100%' : '86rem' },
        }}
      >
        {children}
      </Box>

      <Footer />
    </Box>
  );
};

export default BaseLayout;
