import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { CSS_VARS } from '@/constants/css-vars';

import Radio from '@/components/Forms/Radio';

import type { OptionListIteratorProps, OptionProps } from './types';

export const Option = <OV,>(
  props: OptionProps<OV> & {
    disabled?: boolean;
  },
): JSX.Element => {
  const { disabled, label, value } = props;

  return (
    <FormControlLabel
      control={<Radio />}
      label={
        <Typography
          noWrap
          variant="body1"
          sx={{
            color: disabled ? `var(${CSS_VARS.Colors.Gray[400]})` : ``,
          }}
        >
          {label}
        </Typography>
      }
      value={value}
      disabled={disabled}
    />
  );
};

const OptionListIterator = <OV,>(
  props: OptionListIteratorProps<OV>,
): JSX.Element => {
  const {
    optionList,
    optionListWrapper: OptionListWrapper,
    optionWrapper: OptionWrapper,
  } = props;

  const iteratedList = optionList.map((option) => {
    const hasOptionWrapper = typeof OptionWrapper === 'function';

    if (hasOptionWrapper) {
      return (
        <OptionWrapper key={option.id}>
          <Option {...option} />
        </OptionWrapper>
      );
    }

    return <Option key={option.id} {...option} />;
  });

  const hasOptionListWrapper = typeof OptionListWrapper === 'function';

  if (hasOptionListWrapper) {
    return <OptionListWrapper>{iteratedList}</OptionListWrapper>;
  }

  return <>{iteratedList}</>;
};

export default OptionListIterator;
