import useAuthenticatedUser from '@/api/hooks/useAuthenticateUser';
import useGetBusinessFacilityInformation from '@/api/hooks/useGetBusinessFacilityInformation';
import useUpdateFacilityInformation from '@/api/hooks/useUpdateFacilityInformation';
import RadioGroup from '@/components/Forms/RadioGroup';
import ControlBar from '@/components/UI/ControlBar';
import { Router } from '@/constants/router';
import { useFacilitySectionData } from '@/pages/Facilities/hooks/useFacilitySectionData';
import type { ParsedBooleanValue } from '@/types/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BUSINESS_ACTIVITY_SECTION_ID } from '../types';
import {
  getNormalizedData,
  optionList,
  setThirdPartyPaymentValues,
  THIRD_PARTY_PAYMENT_PAGE_ID,
} from './data';
import { formSchema, FormSchema } from './schema';
import useUpdateBusinessApplicationProgress from '@/api/hooks/useUpdateBusinessApplicationProgress';
import useGetBusinessApplicationProgress from '@/api/hooks/useGetBusinessApplicationProgress';

const ThirdPartyPayment = (): JSX.Element => {
  const navigate = useNavigate();

  const updateFacilityInformation = useUpdateFacilityInformation();

  const authenticatedUser = useAuthenticatedUser();

  const { id: businessId } = authenticatedUser.data?.businesses?.[0] ?? {};

  const { facilityId } = useFacilitySectionData();

  useFacilitySectionData({
    title: 'Are you a Third-Party Payment Processor?',
    description: 'Do you help other merchants process payments.',
  });

  const { control, handleSubmit, setValue } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      'are-you-a-third-party-payment-processor': 'false',
    },
  });

  const {
    data: businessFacilityInformation,
    isPending: isBusinessFacilityLoading,
  } = useGetBusinessFacilityInformation(
    businessId ?? '',
    facilityId ?? '',
    BUSINESS_ACTIVITY_SECTION_ID,
    THIRD_PARTY_PAYMENT_PAGE_ID,
    { enabled: businessId != null && facilityId != null },
  );

  useEffect(() => {
    if (isBusinessFacilityLoading || businessFacilityInformation == null) {
      return;
    }

    setThirdPartyPaymentValues(businessFacilityInformation, setValue);
  }, [businessFacilityInformation, isBusinessFacilityLoading, setValue]);

  const updateBusinessApplicationProgress =
    useUpdateBusinessApplicationProgress();

  const {
    data: businessApplicationProgress,
    refetch: refetchBusinessProgress,
  } = useGetBusinessApplicationProgress(businessId ?? '', {
    enabled: businessId != null,
  });

  const isLoading = updateFacilityInformation.isPending;

  const onSubmitHandler: SubmitHandler<FormSchema> = (facilityData) => {
    if (businessId == null || facilityId == null) return;

    const sectionData = getNormalizedData(facilityData);

    updateFacilityInformation.mutate(
      {
        businessId,
        facilityId,
        section: sectionData,
      },
      {
        async onSuccess() {
          await updateBusinessApplicationProgress.mutateAsync(
            {
              businessId,
              pagesCompleted:
                businessFacilityInformation?.id !== '' ||
                authenticatedUser?.data?.businesses?.[0]?.finishedOnboard
                  ? undefined
                  : (businessApplicationProgress?.pagesCompleted ?? 0) + 1,
            },
            {
              onSuccess() {
                refetchBusinessProgress().catch(console.error);
                navigate(
                  Router.BankAccount.Facilities.BusinessActivity.ATMLocation,
                );
              },
            },
          );
        },
      },
    );
  };

  return (
    <>
      <Grid container flexDirection="column" gap={3}>
        <RadioGroup<ParsedBooleanValue, FormSchema>
          control={control}
          name="are-you-a-third-party-payment-processor"
          options={optionList}
          verticalLayout
        />
      </Grid>
      <Grid container flexDirection={'column'} gap={4}>
        <ControlBar
          backButtonProps={{
            onClick: () => {
              navigate(
                Router.BankAccount.Facilities.BankingActivity.PrimaryBusiness,
              );
            },
          }}
          nextButtonProps={{
            onClick: handleSubmit(onSubmitHandler),
            disabled:
              updateBusinessApplicationProgress.isPending ||
              updateFacilityInformation.isPending,
          }}
          isLoading={isLoading}
        />
      </Grid>
    </>
  );
};

export default ThirdPartyPayment;
