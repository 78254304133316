import { FacilitiesRoutes, Router } from '@/constants/router';
import type { NavigationItem } from '@/features/ui/navigation';
import { nanoid } from 'nanoid';

export const pageList: Array<{ id: string; name: string }> = [
  {
    id: FacilitiesRoutes.Details.__BasePath__,
    name: 'Details',
  },
  {
    id: FacilitiesRoutes.BankingActivity.__BasePath__,
    name: 'Banking Activity',
  },
  {
    id: FacilitiesRoutes.BusinessActivity.__BasePath__,
    name: 'Business Activity',
  },
  {
    id: FacilitiesRoutes.LocationDocs.__BasePath__,
    name: 'Documents',
  },
  {
    id: FacilitiesRoutes.LocationReview.__BasePath__,
    name: 'Review',
  },
];

export const newFacilityItems: NavigationItem[] = [
  {
    id: nanoid(),
    path: Router.BankAccount.FacilitiesLocations.AddNewFacility,
    text: 'Name',
  },
  {
    id: nanoid(),
    path: Router.BankAccount.Facilities.Details.Type,
    text: 'Details',
  },
  {
    id: nanoid(),
    path: Router.BankAccount.Facilities.BankingActivity.PrimaryPurpose,
    text: 'Banking Activity',
  },
  {
    id: nanoid(),
    path: Router.BankAccount.Facilities.BusinessActivity.ThirdPartyPayment,
    text: 'Business Activity',
  },
  {
    id: nanoid(),
    path: Router.BankAccount.Facilities.Documents.UploadDocuments,
    text: 'Documents',
  },
  {
    id: nanoid(),
    path: Router.BankAccount.Facilities.LocationReview.ActivityTierOne,
    text: 'Review & Sign',
  },
];
