import Box from '@mui/material/Box';
import { memo } from 'react';

import { Metadata } from '../BankAccountNavigation/components';
import LocationNavIterator from './components/LocationNavIterator';
import type { LocationNavItem } from './data';

interface OnboardingNavigationProps {
  navItemList: LocationNavItem[];
  toggleMobileSidebarOpen?: () => void;
}

const OnboardingNavigation = memo((props: OnboardingNavigationProps) => {
  const { navItemList, toggleMobileSidebarOpen } = props;

  return (
    <Box
      component="aside"
      sx={{
        display: {
          xs: 'none',
          md: 'flex',
        },
        borderRight: '1px solid',
        borderColor: 'grey.100',
        gridArea: 'sidebar',
        minHeight: '100vh',
        padding: '2rem 2rem 2rem 0',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
      }}
    >
      <LocationNavIterator
        list={navItemList}
        toggleMobileSidebarOpen={toggleMobileSidebarOpen}
      />

      <Metadata />
    </Box>
  );
});

if (import.meta.env.DEV) {
  OnboardingNavigation.displayName = 'OnboardingNavigation;';
}

export default OnboardingNavigation;
