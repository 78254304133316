import { nanoid } from 'nanoid';

import { Router } from '@/constants/router';

import type { NavigationItem } from '@/features/ui/navigation';
import { PAYMENT_ENROLLMENT_ENABLED } from '@/constants/env';
import { SystemRoleType } from '@pages/BankAccount/SystemUsers/__utils__/mock';

const bankAccountNavItems: NavigationItem[] = [
  {
    id: 'dashboard',
    path: Router.BankAccount.Dashboard,
    text: 'Dashboard',
  },
  {
    id: 'account-overview',
    path: Router.BankAccount.Overview,
    text: 'Account Overview',
  },
  {
    id: nanoid(),
    path: Router.BankAccount.PaymentsAndPayees,
    text: 'Payments & Payees',
  },
  {
    id: nanoid(),
    text: 'Authorized Cardholders',
    children: [
      {
        id: nanoid(),
        path: Router.BankAccount.DebitLimits,
        text: 'Debit Limits',
      },
      {
        id: nanoid(),
        path: Router.BankAccount.Cardholders,
        text: 'Cardholders',
      },
    ],
  },
  {
    id: 'business-details',
    path: Router.BankAccount.BusinessDetails,
    text: 'Business Details',
  },
  {
    id: 'location-details',
    path: Router.BankAccount.LocationDetails,
    text: 'Location Details',
  },
  {
    id: 'system-users',
    text: 'System Users',
    children: [
      {
        id: nanoid(),
        path: Router.BankAccount.SystemRoleType,
        text: 'Roles',
      },
      {
        id: nanoid(),
        path: Router.BankAccount.SystemUsers,
        text: 'Users',
      },
    ],
  },
  {
    id: nanoid(),
    path: Router.BankAccount.PaymentSystemEnrollment,
    text: 'Payment Processing',
    isActive: PAYMENT_ENROLLMENT_ENABLED,
  },
];

export const navItems = (
  userRole: string[],
): { bankAccount: NavigationItem[] } => {
  const activeNavItems = bankAccountNavItems.filter(
    (i) => i.isActive !== false,
  );
  if (userRole.includes(SystemRoleType.ADMINISTRATOR)) {
    return {
      bankAccount: activeNavItems,
    };
  }

  if (userRole.includes(SystemRoleType.OPERATING)) {
    return {
      bankAccount: activeNavItems.filter(
        (item) =>
          item.id !== 'business-details' &&
          item.id !== 'location-details' &&
          item.id !== 'system-users',
      ),
    };
  }

  return {
    bankAccount: activeNavItems.filter(
      (item) => item.id === 'dashboard' || item.id === 'account-overview',
    ),
  };
};
